import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'


router.afterEach((to) => {
  // 動的なタイトル設定
  const title = typeof to.meta.dynamicTitle === 'function'
    ? to.meta.dynamicTitle(to)
    : to.meta.title;
  if (title) document.title = title;

  // 動的なディスクリプション設定
  const descriptionMeta = document.querySelector('meta[name="description"]');
  if (descriptionMeta) {
    const description = typeof to.meta.dynamicDescription === 'function'
      ? to.meta.dynamicDescription(to, /* content data */ { body: '記事の本文テキストをここに渡す' })
      : to.meta.description;
    descriptionMeta.setAttribute('content', description || '');
  }
});


loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
