import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '@/components/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '広島・岡山WEB制作 | _old（アンダーバーオールド）',
      description: 'webサイト（ホームページ）制作、マーケティング、運用保守、システム開発、SEO対策、各種撮影などをゼロストップサービス感覚で福山・尾道から。',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    meta: {
      title: 'お問い合わせ | 広島・岡山WEB制作_old（アンダーバーオールド）',
      description: 'こちらは広島県福山市を拠点にオールインワンソリューションを提供するアンダーバーオールドのお問い合わせページです。',
    },
  },
  {
    path: '/thanks',
    name: 'thanks',
    // route level code-splitting
    // this generates a separate chunk (thanks.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thanks" */ '../views/ThanksView.vue'),
    meta: {
      title: 'お問い合わせ完了 | 広島・岡山WEB制作_old（アンダーバーオールド）',
      description: 'こちらは広島県福山市を拠点にオールインワンソリューションを提供するアンダーバーオールドにお問い合わせいただきありがとうございます。',
    },
  },
  {
    path: '/suspend',
    name: 'suspend',
    // route level code-splitting
    // this generates a separate chunk (thanks.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "suspend" */ '../views/SuspendView.vue'),
    meta: {
      title: 'サービス休止中 | 広島・岡山WEB制作_old（アンダーバーオールド）',
      description: 'ただいまサービスは休止中です。',
    },
  },
  {
    path: '/postlist',
    name: 'postlist',
    component: () => import(/* webpackChunkName: "postlist" */ '../views/PostList.vue'),
    meta: {
      title: '記事一覧 | 広島・岡山WEB制作_old（アンダーバーオールド）',
      description: 'こちらは広島県福山市を拠点にオールインワンソリューションを提供するアンダーバーオールドの記事一覧ページです。',
    },
  },
  {
    path: '/postdetail/:id',
    name: 'postdetail',
    component: () => import(/* webpackChunkName: "postdetail" */ '../views/PostDetail.vue'),
    meta: {
      dynamicTitle: (route) => `${route.params.id} | 広島・岡山WEB制作_old`,
      dynamicDescription: (route, content) =>
        content.body ? content.body.replace(/<\/?[^>]+>/g, '').slice(0, 100) : '',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    // name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


// Gate redirect
router.beforeEach(async (to, from, next) => {
  const blockedIPs = [
    '36.2.155.228',
    '118.6.184.102',
    '122.222.192.229',
    '118.6.186.112',
    '122.222.95.160',
    '210.133.193.76'
  ];

  // IPアドレス取得用のAPIを呼び出す例
  const getUserIP = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json'); // 外部APIを利用
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Failed to fetch IP:', error);
      return null; // エラーの場合、nullを返す
    }
  };

  const userIP = await getUserIP();

  if (userIP && blockedIPs.includes(userIP)) {
    if (to.path !== '/suspend') {
      next('/suspend'); // 指定されたIPの場合は /suspend にリダイレクト
    } else {
      next(); // そのまま /suspend に進む
    }
  } else {
    next(); // 指定されたIP以外の場合はリダイレクトせずに進む
  }
});