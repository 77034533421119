// src/api/newt.js
import axios from 'axios';

// Axiosインスタンスを作成
const instance = axios.create({
  baseURL: process.env.VUE_APP_NEWT_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_NEWT_API_KEY}`,
  },
});

// 投稿一覧を取得する関数
export const fetchPosts = async () => {
  const response = await instance.get(''); // パスを指定しない
  return response.data.items;
};

// 特定の投稿を取得する関数
export const fetchPostById = async (id) => {
  const response = await instance.get(`/${id}`); // IDを指定
  return response.data;
};