<template>
<div id="ti">
    <ul>
        <li v-for="post in paginatedPosts" :key="post._id">
            <div class="title">INFO</div>
            <router-link class="tilink" :to="{ name: 'postdetail', params: { id: post._id }}">
            <span class="date">{{ formatDate(post._sys.createdAt) }}</span><span class="category">{{ post.category }}</span><p>{{ post.title }}</p>
            </router-link>
            <router-link class="listlink" to="/postlist"><v-icon icon="mdi mdi-format-list-bulleted" /></router-link>
        </li>
    </ul>
</div>

</template>



<script>
import { fetchPosts } from '../api/newt';

export default {

//   name: 'PostList',

  data() {
    return {
        posts: [],
        currentPage: 1, // 現在のページ番号
        itemsPerPage: 1, // 1ページに表示する記事数
    };
  },

  computed: {
    /**
     * 現在のページに表示する記事を計算
     * @returns {Array} 現在のページの記事リスト
     */
    paginatedPosts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.posts.slice(startIndex, endIndex);
    },
    /**
     * 総ページ数を計算
     * @returns {number} ページ数
     */
    totalPages() {
      return Math.ceil(this.posts.length / this.itemsPerPage);
    },
  },

  async created() {
    try {
      this.posts = await fetchPosts();
    } catch (error) {
      console.error('記事一覧の取得に失敗しました:', error);
    }
  },

  methods: {
    /**
     * 公開年月日をフォーマットする
     * @param {string} isoDate ISO 8601 形式の日付
     * @returns {string} フォーマット済みの日付 (YY/MM/DD)
     */
    formatDate(isoDate) {
      if (!isoDate) return '';
      const date = new Date(isoDate);
      const year = String(date.getFullYear()); // YYYY
      const month = String(date.getMonth() + 1).padStart(2, '0'); // MM
      const day = String(date.getDate()).padStart(2, '0'); // DD
      return `${year}/${month}/${day}`;
    },
  },

}

</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
  #ti {
    position: relative;
    z-index: 1;
    ul {
        li {
            text-align: left;
            display: flex;
            // justify-content: center;
            align-items: center;
            .title {
                font-family: 'Montserrat', sans-serif;
                transform: rotate(-90deg);
                width: 50px;
                text-align: center;
                color: #fff;
            }
            .listlink {
                font-size: 30px;
                width: 50px;
                i {
                  background:#f5f5f5;
                  border-radius: 6px;
                  color: #000;
                  transition: .6s;
                  border: solid 1px lightgray;
                }
                &:hover {
                  i {
                    background: lightgray;
                    border-color: lightgray;
                  }
                }
            }
            .tilink {
                text-decoration: none;
                color: #000;
                max-width: calc(100% - 100px);
                box-sizing: border-box;
                padding-left: 1%;
                padding-right: 20px;
                p {
                    text-decoration: underline;
                    font-size: unquote("clamp(1rem, 0.818rem + 0.45vw, 1.125rem)");
                    padding-top: 2px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                }
                .category {
                    display: inline-block;
                    padding: 0 4px;
                    font-size: smaller;
                    margin-left: 8px;
                    border: solid 1px #000;
                    position: relative;
                    top: -2px;
                }
                &:hover {
                    p {
                        text-decoration: none;
                    }
                }
            }
        }
    }
  }
</style>
